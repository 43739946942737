// Utility classes

// Lighter font color
.tp-light {
  color: $text-color-light;
}

// center positioning
.tp-center {
  text-align: center;
}

// General styling of slope sections
.testimonial {
  background: $gray-gradient;
  @include slope-section($light-bg-color, up);
  z-index: 0;
}

#news {
  z-index: 1;
  @include slope-section($contrast-color, down);
}

#features {
  background: $gray-gradient;
  @include slope-section($light-bg-color, up);
  z-index: 0;
}

#demo {
  z-index: 1;
  @include slope-section($contrast-color, down);
}

#vision {
  background: $gray-gradient;
  z-index: 1;
  @include slope-section($light-bg-color, up);
}

#support {
  z-index: 1;
  @include slope-section($contrast-color, down);
}

footer {
  @include slope-section($footer-bg-color, up);
}

// Section Headlines
.tp-headline {
  text-align: center;
  @media only screen and (min-width: $small-screen-min) {
    margin-bottom: 4rem;
  }
}

// First Subheadline in demo section
.demo-info h3:first-of-type {
  margin-top: 0;
}

// Banner

header {
  background-image: url(../img/graphics/icons-bg-light-gray.png),$blue-gradient;
  background-repeat: repeat;
  background-size: 35%, contain;

  @media only screen and (min-width: $large-screen-min) {
    background-size: 27%, cover;
  }

  @media only screen and (min-width: $x-large-screen-min) {
    background-size: 12%, cover;
  }
}

.tp-banner {
  padding-top: 50px;
  height: auto;

  >div {
    margin-top: 40px;

    &:last-of-type {
      margin: 40px 0 60px 0;
      @media only screen and (min-width: $small-screen-min) {
        margin-bottom: 80px;
      }
    }
  }

  #tp-logo {
    height: 140px;
    width: 100%;
    @media only screen and (min-width: $small-screen-min) {
      height: 100%;
      max-height: 200px;
      padding: 32px 0;
      width: 100%;
    }
  }

  h1 {
    color: $brand-color-primary;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 30px;
  }
}


// Key features

.figure-wrapper {
  position: relative;
  margin-left: 80px;
  @extend .left;
}

.feature-headline {
  margin: 0 0 .5rem 0;
}

.feature-description {
  margin-top: 0;
}

.figure {
  &.workflow {
    margin: 0;
    @media only screen and (min-width: $small-screen-min) {
      margin: 40px 0;
    }

    svg {
      width: 100%;
      height: auto;
      min-height: 350px;
      @media only screen and (min-width: $small-screen-min) {
        min-height: 500px;
      }
    }
  }

  &.feature-icon {
    width: auto;
    position: absolute;
    top: 7px;
    left: -80px;
  }
}

.feature-item {
  @extend .tp-column;
  width: 100%;
  @media only screen and (min-width: 800px) {
    width: 50%;
  }
}

.feature-tile {
  margin-top: 2rem;
  @extend .feature-item;
}

// News sections
.news-graphic-wrapper {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 30px;
  @media only screen and (min-width: 992px) {
    position: relative;
    width: 33%;
  }
}

.news-info {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 30px;
  @media only screen and (min-width: 992px) {
    width: 66%;
  }

  h2 {
    margin-top: 0;
  }
}

// Demo section with screencast gifs

.demo-graphic-wrapper {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 30px;
  @media only screen and (min-width: 992px) {
    position: relative;
    width: 67%;
  }
}

.tp-columns.tp-second {
  @media only screen and (min-width: 992px) {
    float: right !important;
  }
}

.demo-item:not(:last-of-type) {
  @media only screen and (min-width: $small-screen-min) {
    margin-bottom: 50px;
  }
}

.demo-info {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 30px;
  @media only screen and (min-width: 992px) {
    width: 33%;
  }

  h2 {
    margin-top: 0;
  }
}

.demo-image {
  img {
    width: 100%;
    height: auto;
  }
}

// Showcases

.testimonial {
  // make space for subheadline
  h2 {
    margin-bottom: 0;
  }
  // shift margin-bottom to subheadline
  h3 {
    margin-bottom: 4rem;
  }
}

.testimonial-content {
  @extend .tp-column-3;
  margin-bottom: 4em;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media only screen and (min-width: $small-screen-min) {
    margin-bottom: 0;
  }

  h2, h3 {
    margin-bottom: 0;
    text-align: center;
  }

  p {
    text-align: justify;
  }

  h3 > a {
    text-decoration: none;
    color: $text-color;
    &:hover {
      color: $link-hover-color;
    }
  }
}

.up-to-top {
  > div {
    text-align: right;
  }
  a {
    text-decoration: none;
  }
}

// modal image

.tp-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.tp-textlink-middle {
  @extend .tp-center;
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.container {
  position: relative;
  width: 100%;

  &:hover {
    .tp-image {
      opacity: 0.3;
    }

    .tp-textlink-middle {
      opacity: 1;
    }
  }
}

.round {
  overflow: hidden;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin: 0 auto;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.4);
}
