//******** Timeline ********//

$bulletSizeSmall :      8px;
$bulletSizeBig :       24px;
$bulletOffsetMobile:  -50px;
$translateValue:       -50%;

// Position wrapper
.timeline {
  position: relative;

  // Timeline articles wrapper
  .entries {
    width:calc(100% - 80px);
    margin: auto;
    position: relative;
    left: -5px;

    // Vertical line
    &:before {
      width: 4px;
      background: $brand-color-secondary;
      position: absolute;
      left: 0;
      transform: none;
      content: '';
      top: 26px;
      height: 85%;

      @media only screen and (min-width: $medium-screen-min) {
        left: 50%;
        transform: translateX(-50%);
        content: '';
        top: 40px;
        height: 83%;
      }
    }
  }

  // Articles in timeline
  // left aligned on small screens
  // alternate left + right alignment on large screens
  .entry {
    padding: 10px 20px 10px 32px;
    float: none;
    clear: both;
    transform: none;
    text-align: left;

    &:nth-child(2n) {
      text-align: left;
      float: none;
      clear: both;
    }

    @media only screen and (min-width: $medium-screen-min) {
      clear: both;
      width:calc(50% - 50px);
      text-align: right;

      &:not(:first-child) {
        margin-top: -60px;
      }

      &:nth-child(2n) {
        text-align:left;
        float: right;

        .title {
          // bullets
          &:before {
            left: -86px;
          }
          &.big:before {
            transform:translate(-8px, $translateValue);
          }
        }
      }
    }
  }

  // Entry headlines
  .title {
    @extend .h3;
    margin: 0 0 12px 0;
    position: relative;
    color: $brand-color-primary;
    line-height: .9em;

    @media only screen and (min-width: $medium-screen-min) {
      line-height: 1.8em;
    }

    // Bullets (tied to headlines)
    // left-aligned per default
    &:before {
      content: '';
      position: absolute;
      width: $bulletSizeSmall;
      height: $bulletSizeSmall;
      border:4px solid $brand-color-secondary;
      background-color: $brand-color-primary;
      border-radius: 100%;
      top: 50%;
      left: $bulletOffsetMobile;
      z-index: 1000;

      // override left alignment for alternate position
      @media only screen and (min-width: $medium-screen-min) {
        transform:translateY($translateValue);
        left: auto;
        right: -74px;
      }
    }

    &.big:before {
      width: $bulletSizeBig;
      height: $bulletSizeBig;
      transform:translate(8px, $translateValue);
    }
  }
  // Entry texts
  p {
    margin: 0;
    @media only screen and (max-width: $small-screen-max) {
      margin: 0;
    }
  }
}
