//******** Base ********//


*, *:before, *:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%; // 16px = 1rem
}

body {
  color: $text-color;
  padding: 0;
  margin: 0;
  position: relative;
  cursor: auto;
  overflow-x: hidden;
}

// Hack for clearfixes
.lt-ie9 {
  * {
    filter: none !important;
  }
}

[aria-hidden=true] {
  display: none !important;
}


ul, ol, dl {
  margin-bottom: 1.25rem;
  list-style-position: outside;
}

ul, ol {
  margin-left: 20px;
  padding: 0;
}

li {
  margin-left: 0;
}

.left {
  float: left !important;
}

.list-plain {
  list-style-type: none;
}

a {
  color: inherit;
  &:hover,
  &:active {
    color: $link-hover-color;
  }
}
