// *********** Navigation *********//

.top-bar {
  overflow: hidden;
  height: $topbar-height;
  line-height: $topbar-height;
  position: fixed;
  width: 100%;
  background: $brand-color-primary;
  margin-bottom: 0;
  z-index: 10;
  @media only screen and (min-width: $large-screen-min) {
    overflow: visible;
    background: $color-bg-navigation-bar;
    &:before,
    &:after {
      content: " ";
      display: table;
    }
  }
  a {
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-weight: bold;
    color: $contrast-color;
    display: block;

    &:hover {
      background: $link-hover-color;
      transition: all 0.2s ease-in-out !important;
    }

    > li {
      padding-left: 0;
    }
  }

  svg {
    height: 35px;
    width: auto;
  }
}

.top-bar-section {
  left: 0;
  position: relative;
  width: auto;
  transition: left 300ms ease-out;

  ul {
    li:first-child {
      @media only screen and (max-width: 64em) {
        display: none;
      }
    }
  }


  li {
    line-height: $topbar-height;
  }
  a {
    font-size: .8em;
    padding: 0 .9rem;
    @media only screen and (min-width: $small-screen-min) {
      padding: 0 1.04167rem;
      font-size: 1em;
    }
  }
}

.tei-publisher-icon {
  text-align: left;
  padding: 8px .9rem 0 1.04167rem !important;
  height: 50px;
}

// Title Area
.title-area {
  position: relative;
  margin: 0;
  list-style-type: none;

  @media only screen and (min-width: $large-screen-min){
    float: left;
  }
}

.name {
  height: $topbar-height;
  margin: 0;

  @media only screen and (min-width: $large-screen-min) {
    display: none;
  }

  h1, h2, h3, h4, p, span {
    line-height: $topbar-height;
    margin: 0;
    a {
      width: 75%;
      display: block;
    }
  }
}

// Menu toggle button on small devices
.toggle-topbar {
  position: absolute;
  right: 0;
  top: 0;
  @media only screen and (min-width: $large-screen-min) {
    display: none;
  }

  a {
    position: relative;
    display: block;
    height: $topbar-height;
    line-height: $topbar-height;
  }

  &.menu-icon {
    a {
      height: $topbar-height;
      line-height: $topbar-height;
      padding: 0 2.60417rem 0 1.04167rem;
      position: relative;
    }
  }
}

.menu-icon a span:after {
  content: "";
  position: absolute;
  display: block;
  height: 0;
  top: 50%;
  margin-top: -6px;
  right: 1.04167rem;
  box-shadow: 0 0 0 1px $topbar-menu-icon-color-toggled,
  0 7px 0 1px $topbar-menu-icon-color-toggled,
  0 14px 0 1px $topbar-menu-icon-color-toggled;
  width: 18px;
}

.top-bar-section .divider,
.top-bar-section [role="separator"] {
  border-top: solid 0px gray;
  clear: both;
  height: 1px;
  width: 100%;
  @media only screen and (min-width: $large-screen-min) {
    border-bottom: none;
    border-top: none;
    border-right: solid 0px $contrast-color;
    clear: none;
    height: 3.125rem;
    width: 0;
  }
}

.top-bar-section {
  ul {
    padding: 0;
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    @extend .list-plain;

    @media only screen and (min-width: $large-screen-min) {
      width: auto;
      height: auto !important;
      display: inline;
    }

    li {
      background: $contrast-color;
      text-align: center;
      @media only screen and (min-width: $large-screen-min) {
        float: left;
      }

      &>a {
        color: $contrast-color;
        display: block;
        width: 100%;
      }
      a {
        background-color: $color-bg-dropdown-menu;
        @media only screen and (min-width: $large-screen-min) {
          background-color: $brand-color-primary;
        }
      }
    }
  }
}

.top-bar.expanded {
  height: auto;
  background: transparent;
  .title-area {
    background-color: $color-bg-navigation-bar;
  }
}
