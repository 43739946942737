/* Buttons */

.tp-btn {
  display:               inline-block;
  color:                 $brand-color-primary !important;
  padding:               8px 16px 8px 52px;
  margin-bottom:         7px;
  margin-right:          5px;
  font-size:             1.0rem;
  font-weight:           900;
  letter-spacing:        2px;
  vertical-align:        middle;
  text-align:            center;
  text-decoration:       none;
  -webkit-border-radius: 4px;
  -moz-border-radius:    4px;
  border-radius:         4px;
  cursor:                pointer;
  background-color:      transparent;
  border:                3px solid $brand-color-secondary;
  -webkit-transition:    all 0.3s ease-out;
  -moz-transition:       all 0.3s ease-out;
  -ms-transition:        all 0.3s ease-out;
  -o-transition:         all 0.3s ease-out;
  transition:            all 0.3s ease-out;
  position: relative;
  font-family: $font-family-sans;


  @media only screen and (min-width: $small-screen-min) {
    padding:               10px 18px 10px 55px;
    margin-bottom:         7px;
    margin-right:          5px;
    font-size:             1.3rem;
  }

  &:hover {
    background-color: $brand-color-secondary;
    color: $brand-color-primary !important;
  }

  &:focus {
    color: $contrast-color;
    outline: none;
  }
}

.tp-demo-button-container {
  text-align: center;
  #icon-eye {
    width: auto;
    position: absolute;
    top: 11px;
    left: 11px;
    height: 23px;
    @media only screen and (min-width: $small-screen-min) {
      top: 16px;
      left: 8px;
      height: 26px;
    }
  }
}

.tp-btn-link-wrapper {
  @extend .tp-center;
  margin-top: 2rem;
}

.tp-btn-link {
  background-color: $brand-color-secondary;
  font-size: inherit;
  color: $text-color;
  font-weight: 700;
  text-decoration: none;
  padding: .8em 1.2em;
  position: relative;
  display: inline-block;

  &:hover {
    color: inherit;
    background-color: lighten($brand-color-secondary, 15%);
  }

  @media (min-width: $small-screen-min) {
    font-size: $tp-font-base;
    padding: 1em 2em;
  }

  &.icon {
    padding: .5em .8em .5em 3em;

    @media (min-width: $small-screen-min) {
      font-size: $tp-font-base;
      padding: .7em 1em .7em 3.2em;
    }

    #icon-eye {
      width: auto;
      position: absolute;
      top: 7px;
      left: 5px;
      height: 20px;

      @media only screen and (min-width: $small-screen-min) {
        top: 12px;
        left: 7px;
        height: 26px;
      }
    }
  }
}
