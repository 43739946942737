// Slope Section mixin

// Slope section angles
$slope-up:    30px 100vw 0 0;
$slope-down:  0 100vw 30px 0;

@mixin slope-section ($bg, $direction:up) {
  padding: 1.3em 0 3.5em 0;
  position: relative;
  margin-top: 0;

  @media screen and (min-width: $small-screen-min) {
    padding: 2em 0 3.5em 0;
  }

  &:before {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    content: "";

    @if $direction == "up" {
      border-width: $slope-up;
      border-color: transparent $bg transparent transparent;
    }
    @else if $direction == "down" {
      border-width: $slope-down;
      border-color: transparent transparent $bg transparent;
    }
  }
}
