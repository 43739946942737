//******** Grid ********//


// Columns

.tp-column, .tp-columns {
  position: relative;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;

  @media only screen and (min-width: $medium-screen-min) {
    float: left;
    min-height: 1px;
  }
}

.large-3 {
  @media only screen and (min-width: $large-screen-min) {
    width: 33.3%;
  }
}

.large-4 {
  @media only screen and (min-width: $large-screen-min) {
    width: 25%;
  }
}

.large-6 {
  @media only screen and (min-width: $large-screen-min) {
    width: 50%;
  }
}

.medium-3 {
  @media only screen and (min-width: $medium-screen-min) {
    width: 33.3%;
  }
}

.medium-6 {
  @media only screen and (min-width: $medium-screen-min) {
    width: 50%;
  }
}

.small-12 {
    width: 100%;
}

.x-small-12 {
  @media screen and (min-width: $small-screen-min -1px) {
    width: 100%;
  }
}

.tp-column-3 {
  @extend .x-small-12;
  @extend .medium-3;
  @extend .large-3;
  @extend .tp-column;
}

.tp-column-4 {
  @extend .large-4;
  @extend .medium-6;
  @extend .tp-column;
}

.tp-column-6 {
  @media only screen and (min-width: $medium-screen-min) {
    width: 50%;
    float: right;
  }
}

.tp-column-fullwidth {
  @extend .small-12;
  @extend .tp-columns;
  @extend .tp-column;
}

// Rows

.tp-row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 62.5rem;
}

.tp-row:before, .tp-row:after {
  content: " ";
  display: table;
}

.tp-row:after {
  clear: both;
}
