//******** Variables ********//

// Colors
$brand-color-primary:   #002B61;
$brand-color-secondary: #F8B316;
$color-primary-light:   #1c8ebb;
$section-bg-color:      #cae6f6;
$text-color:            #3d3b3b;
$text-color-light:      lighten($text-color, 40%);
$footer-bg-color:       #e6e6e6;
$light-bg-color:        #e7f0f6;
$paper-bg-color:        #f2f2e8;
$contrast-color:        #fff;
$link-hover-color:      #094A7B;

$blue-gradient:       linear-gradient(150deg, #1072bb 11%,#1F94BB 45%,$section-bg-color 95%);
// $gray-gradient:       linear-gradient(150deg, $light-bg-color 16%, $section-bg-color 45%, $light-bg-color 91%);
$gray-gradient:       linear-gradient(150deg, $light-bg-color 91%, $section-bg-color 45%, $light-bg-color 16%);

// Breakpoints
$small-screen-min:      480px;
$small-screen-max:      711px;
$medium-screen-min:     712px;
$large-screen-min:      1024px;
$x-large-screen-min:    1800px;

// Font-Sizes
$tp-font-base:          1rem;
$tp-font-small:         .8rem;

$font-family-default:   "Open Sans", sans-serif;
$font-family-sans:      "Oswald", Helvetica, sans-serif;

// Navigation
$color-bg-navigation-bar:        $brand-color-primary;
$topbar-height:                  3.125rem;
$topbar-menu-icon-color-toggled: $contrast-color;
$color-bg-dropdown-menu:         $color-primary-light;
